import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Luxury Drive Rentals
			</title>
			<meta name={"description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:title"} content={"Luxury Drive Rentals"} />
			<meta property={"og:description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link5" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші першокласні послуги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					У Luxury Drive Rentals ми пропонуємо більше, ніж просто автомобілі – ми надаємо виняткові враження від водіння. Наш асортимент послуг створений, щоб задовольнити будь-які ваші потреби, гарантуючи, що ваша подорож буде максимально розкішною та бездоганною. Від нашого автопарку найвищого рівня до наших персоналізованих послуг, ми прагнемо перевершувати ваші очікування на кожному кроці.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10:17:01.508Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-3.jpg?v=2024-06-19T10%3A17%3A01.508Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10:17:01.521Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						object-position="right"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Незрівнянний флот
				</Text>
				<Text
					as="h1"
					margin="0px 0px 2rem 0px"
					font="--lead"
					color="--dark"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Наша колекція розкішних автомобілів підібрана, щоб запропонувати найкращі характеристики, стиль і комфорт. Кожен автомобіль ретельно обслуговується та оснащений новітніми технологіями, щоб забезпечити чудові враження від водіння. Незалежно від того, чи шукаєте ви елегантний спортивний автомобіль, елегантний седан або потужний позашляховик, у нашому автопарку є щось на будь-який смак і на будь-який випадок.
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Ми розуміємо, що кожен клієнт має унікальні потреби та переваги. Ось чому ми пропонуємо ряд персоналізованих послуг, щоб зробити вашу оренду справді винятковою. Від індивідуальних маршрутів до послуг водія, наша команда тут, щоб задовольнити ваші конкретні вимоги.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Пропозиція оренди автомобілів класу люкс
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Різноманітні варіанти автопарку: вибирайте з безлічі розкішних автомобілів, кожен з яких вибрано за виняткову якість і продуктивність.
 Спортивні автомобілі: відчуйте гострі відчуття від водіння високопродуктивних спортивних автомобілів, ідеальних для тих, хто жадає швидкості та маневреності.
 Седани: насолоджуйтеся неперевершеним комфортом і елегантністю з нашим асортиментом розкішних седанів, ідеальних для ділових поїздок або неквапливих поїздок.
 Позашляховики: впевнено пересувайтеся будь-якою місцевістю на наших потужних і просторих позашляховиках, створених для пригод і досліджень.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Індивідуальні плани оренди
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Ми пропонуємо гнучкі плани оренди, адаптовані до ваших потреб.
 Короткострокова оренда: наша короткострокова оренда ідеально підходить для відпочинку на вихідних або особливих подій, забезпечує максимальну гнучкість.
 Довгострокова оренда: наша довгострокова оренда ідеально підходить для тривалого перебування або ділових поїздок, пропонує високу цінність і зручність.
 Корпоративна оренда: наші плани корпоративної оренди розроблені для задоволення потреб ділових поїздок, пропонуючи транспортні засоби та послуги преміум-класу.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Ексклюзивні послуги водія
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Насолоджуйтеся розкішшю водія професійного водія.
 Шофер на заході: зробіть урочистий вхід на ваш наступний захід за допомогою наших ексклюзивних послуг водія.
 Бізнес-шофер: забезпечте своєчасне та професійне транспортування для ділових зустрічей та заходів.
 Особистий водій: відчуйте неперевершену зручність і розкіш із відданим особистим водієм.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Додатки преміум-класу
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Покращте умови оренди за допомогою нашого вибору додатків преміум-класу.
 GPS-навігація: ніколи не заблукайте з нашими передовими системами GPS-навігації.
 Дитячі крісла: подорожуйте безпечно з нашим асортиментом дитячих крісел, доступних за запитом.
 Додаткові водії: поділіться досвідом водіння з додатковими водіями, доданими до вашої угоди про оренду.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Комплексна підтримка
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Наша команда підтримки доступна цілодобово і без вихідних, щоб допомогти з будь-якими потребами чи запитами.
 Цілодобова підтримка клієнтів: зв’яжіться з нами будь-коли, вдень чи вночі, щоб отримати допомогу з орендою.
 Допомога на дорозі: їдьте спокійно, знаючи, що наша допомога на дорозі — лише один дзвінок.
 Послуги консьєржа: дозвольте нашій команді консьєржів допомогти з бронюванням ресторану, квитками на події тощо.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--lead" color="--dark" sm-text-align="left">
						<Em>
							<Strong>
								І це лише початок. Щоб дізнатися більше про весь спектр наших послуг і про те, як ми можемо зробити ваші враження від водіння незабутніми, зв’яжіться з нами сьогодні або відвідайте нас особисто. Наша команда готова допомогти вам із будь-якими запитаннями та надати детальну інформацію про наші пропозиції.
							</Strong>
						</Em>
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});